.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.section-results {
    margin-top: 60px;
}

.tb-data {
    display: flex;
    justify-content: space-between;
    min-width: 25vw;
}

.tb-data > p {
    padding: 10px 5px;
    font-size: 1rem;
    font-weight: 500;
}

.title {
    color: #363636;
    display: block;
    font-size: 1.1rem;
    font-weight: bold;
}

button {
    outline: none;
}

@media screen and (max-width: 1080px) {
    .tb-data {
        min-width: 50vw;
    }
}
