/* Variables */
:root {
    /* Fonts */
    --font-family-mulish-black: 'Mulish-Black';
    --font-family-mulish-bold: 'Mulish-Bold';
    --font-family-mulish-medium: 'Mulish-Medium';
    --font-family-mulish-regular: 'Mulish-Regular';
}

body,
html {
    margin: 0;
    padding: 0;
    max-width: 100vw;
    overflow-x: hidden;
}

.section-convida {
    padding: 5rem 0rem;
}

.explanation-section {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.explanation-review-section {
    height: 100%;
    max-width: 90vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-title-explanation-section {
    font-family: var(--font-family-mulish-regular);
    font-size: 30px;
    color: #00aba0;
    font-weight: 600;
    line-height: 36px;
    letter-spacing: 0.1px;
}

.title-explanation-section {
    font-family: var(--font-family-mulish-regular);
    font-size: 54px;
    color: #333333;
    font-weight: 700;
    line-height: 67px;
    letter-spacing: 0.2px;
}

.title-section {
    font-family: var(--font-family-mulish-regular);
    font-size: 34px;
    color: #333333;
    font-weight: 700;
    line-height: 40px;
    letter-spacing: 0.2px;
    text-align: center;
}

.description-explanation-section {
    font-family: var(--font-family-mulish-regular);
    font-weight: 500;
    font-size: 20px;
    line-height: 38px;
    letter-spacing: 0.2px;
    color: #8f8f8f;
}

.is-primary-button-cv {
    background-color: #00aba0;
    border-color: transparent;
    color: #fff;
    font-weight: bold !important;
    white-space: normal;
    padding: 10px auto;
    height: auto;
    /* width: 100%; */
}

.is-primary-button-cv:hover {
    background-color: #00aba0;
    border-color: transparent;
    color: #fff;
    font-weight: bold !important;
}

.is-primary-button-cv:visited {
    background-color: #00aba0;
    border-color: transparent;
    color: #fff;
    font-weight: bold !important;
}

.is-primary-outlined-button-cv {
    background-color: #000;
    border-color: #00aba0;
    color: #fff;
    font-weight: bold !important;
}

.is-primary-outlined-button-cv:hover {
    background-color: #000;
    border-color: #00aba0;
    color: #fff;
    font-weight: bold !important;
}

.is-primary-outlined-button-cv:active,
.is-primary-outlined-button-cv:visited,
.is-primary-outlined-button-cv:focus {
    background-color: #000;
    border-color: #00aba0;
    color: #fff !important;
    font-weight: bold !important;
}

.mtb-0 {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.mt-10 {
    margin-top: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.mt-30 {
    margin-top: 30px;
}

.mb-20 {
    margin-bottom: 20px;
}

.first-section-bg {
    background-image: url('../images/bg-1.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.second-section-bg {
    background-image: url('../images/bg-2.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.third-section-bg {
    background-image: url('../images/bg-3.png');
    background-repeat: no-repeat;
    background-size: cover;
}

.title-footer {
    font-family: var(--font-family-mulish-regular);
    font-weight: 900;
    font-size: 18px;
    line-height: 26px;
    text-transform: uppercase;
    color: #333333;
}

.option-footer {
    font-family: var(--font-family-mulish-regular);
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
}

.option-footer:hover {
    color: #333333;
}

/*Begin Terms of service Screen*/
.title-terms-of-service {
    font-family: var(--font-family-mulish-black);
    font-size: 24px;
    text-transform: uppercase;
    color: #000000;
}

.header-terms-of-service {
    font-family: var(--font-family-mulish-medium);
    font-size: 16px;
    color: #282828;
}

.header-condition-terms-of-service {
    font-family: var(--font-family-mulish-black);
    font-size: 16px;
    color: #282828;
}

.description-condition-terms-of-service {
    font-family: var(--font-family-mulish-regular);
    font-size: 16px;
    color: #282828;
}

.footer-terms-of-service {
    font-family: var(--font-family-mulish-black);
    font-size: 16px;
    color: #282828;
}

.faq-container {
    /* display: inline-block; */
    position: relative;
    width: 100%;
}

.toggle-item {
    border-radius: 16px;
    border: 1px solid #dce0e3 !important;
    padding: 20px 0;
    margin-top: 25px;
    height: auto;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    white-space: normal;
    box-shadow: none !important;
    z-index: 1;
    position: relative;
}

.white-toggle-item {
    background-color: #fff;
}

.grey-toggle-item {
    background-color: #f9f9f9;
}

.faq-question {
    font-size: 18px;
    font-family: var(--font-family-mulish-bold);
    color: #333333;
    padding-right: 10px;
    text-align: start;
}

.faq-icon {
    width: 34px;
    height: 34px;
    transition: all 0.5s ease-in-out;
    /* object-fit: contain; */
}

.header-faq-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 30px;
}

.content-faq-container {
    border: 1px solid #dce0e3;
    padding: 20px 30px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-top: -12px;
    z-index: 999;
    position: relative;
}

.carousel-container {
    margin-top: 40px;
}
.card-container {
    margin-bottom: 60px;
    padding: 44px 24px;
    border-radius: 24px;
    border: 1px solid #ebebeb;
    width: 360px;
    display: flex;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.card-title {
    font-size: 28px;
    color: #525252;
    font-family: var(--font-family-mulish-bold);
}

.card-description {
    font-size: 18px;
    color: #525252;
    font-family: var(--font-family-mulish-bold);
    text-align: center;
    margin-bottom: 30px;
}

.quote-icon {
    width: 44px;
    height: 44px;
    object-fit: contain;
    margin: 20px 0;
}

.star-icon {
    width: 30px;
    height: 30px;
    object-fit: contain;
    padding: 0 4px;
}

.react-multi-carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
}
.react-multi-carousel-dot {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.react-multi-carousel-dot button {
    width: 6px !important;
    height: 6px !important;
    background-color: #525252 !important;
    border: none !important;
}

.react-multi-carousel-dot--active button {
    width: 12px !important;
    height: 12px !important;
    background-color: #a3459e !important;
}

/*Begin Write your review Screen*/
.review-section {
    background-color: #00b5a9;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin: 0 3rem;
    margin-bottom: -50px;
    padding-bottom: 66px;
    padding-top: 80px;
}

.review-section__title {
    font-family: var(--font-family-mulish-bold);
    font-size: 30px;
    font-weight: 700;
    line-height: 50px;
    text-align: center;
}

.review-section__description {
    font-family: var(--font-family-mulish-regular);
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 0 !important;
    opacity: 0.6;
    text-align: center;
}

.review-section__button,
.review-section__button:hover,
.review-section__button:focus,
.review-section__button:active {
    background: #ffffff;
    border-radius: 45px;
    border: none;
    color: #00aba0;
    font-family: var(--font-family-mulish-regular);
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    margin-top: 30px;
    text-align: center;
    width: 309px;
}

.height-100 {
    height: 90%;
}

.stores-icon img {
    width: 240px;
    height: 75px;
    object-fit: contain;
}

.img-convida {
    object-fit: contain;
}

.phone-preview {
    position: relative;
    max-width: 320px;
    width: 100%;
    border-radius: 50px;
    overflow: hidden;
}

.phone-preview::before {
    content: '';
    display: block;
    padding-bottom: 210%;
}

.phone-video-mask {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    background-repeat: no-repeat;
    z-index: 2;
    background-size: 100% 100%;
    background-image: url(/src/assets/images/iphone-mask.png);
}

.phone-video video {
    object-fit: contain;
    position: relative;
    width: 84%;
    height: 90%;
    top: 9%;
}

.phone-preview .phone-video {
    position: absolute;
    display: flex;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    /*background: #fff;*/
}

.social-media-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    margin-left: 20px;
}

.social-media-item:first-child {
    margin-left: 0px;
}

.social-media-icon {
    width: 22px;
    height: 22px;
    object-fit: contain;
}

.gradient-container-convida {
    background: linear-gradient(
        72.77deg,
        rgba(128, 213, 207, 0.5) 11.72%,
        rgba(142, 215, 232, 0.1) 98.52%
    );
    border-radius: 24px;
    padding: 30px;
}

/* Media Queries */
@media only screen and (max-width: 1024px) {
    .footer .container {
        padding-left: 1.5rem !important;
        padding-right: 1.5rem !important;
    }
}

@media only screen and (max-width: 750px) {
    .title-section {
        font-size: 30px;
    }
}

@media only screen and (max-width: 768px) {
    .review-section {
        border-radius: 0px;
        margin: 0px;
    }
    .footer .columns.is-centered,
    .footer .columns.is-centered .column,
    .footer .column.center-column {
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column;
    }
    /*Footer*/
}

@media only screen and (max-width: 460px) {
    .additional-info-container {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .social-media-container {
        margin-top: 20px;
    }
    .phone-preview {
        max-width: 260px;
    }
}

@media only screen and (max-width: 400px) {
    .card-title {
        font-size: 24px;
    }
}

@media only screen and (max-width: 400px) {
    .card-container {
        width: 300px;
    }
}
/*End Terms of service Screen*/
