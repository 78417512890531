@font-face {
    font-family: 'Mulish-ExtraLight';
    src: local('Mulish-ExtraLight'),
        url('../fonts/Mulish-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish-Medium';
    src: local('Mulish-Medium'),
        url('../fonts/Mulish-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish-Black';
    src: local('Mulish-Black'),
        url('../fonts/Mulish-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish-SemiBoldItalic';
    src: local('Mulish-SemiBoldItalic'),
        url('../fonts/Mulish-SemiBoldItalic.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish-Bold';
    src: local('Mulish-Bold'),
        url('../fonts/Mulish-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish-SemiBold';
    src: local('Mulish-SemiBold'),
        url('../fonts/Mulish-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish-ExtraBold';
    src: local('Mulish-ExtraBold'),
        url('../fonts/Mulish-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Mulish-Regular';
    src: local('Mulish-Regular'),
        url('../fonts/Mulish-Regular.ttf') format('truetype');
}
